import { FormGroup } from "@angular/forms";
import { CorrectionsBillToFormEnum } from "@shared/enums/corrections/corrections-form/billto/corrections-billto-form.enum";
import { CorrectionsFormEnum } from "@shared/enums/corrections/corrections-form/corrections-form.enum";
import { CorrectionsBilltoFormModel } from "@shared/models/corrections/corrections-form/billto/corrections-billto.model";

export abstract class CorrectionsBillToGetForm {
  static getForm = (form: FormGroup): FormGroup => {
    return form.controls[CorrectionsFormEnum.billto] as FormGroup;
  }

  static getFormValues = (form: FormGroup): CorrectionsBilltoFormModel => {
    const customerNumber = form.controls[CorrectionsBillToFormEnum.customerNumber];
    const madCode = form.controls[CorrectionsBillToFormEnum.madCode];
    const nameOne = form.controls[CorrectionsBillToFormEnum.nameOne];
    const nameTwo = form.controls[CorrectionsBillToFormEnum.nameTwo];
    const address = form.controls[CorrectionsBillToFormEnum.address];
    const city = form.controls[CorrectionsBillToFormEnum.city];
    const state = form.controls[CorrectionsBillToFormEnum.state];
    const zipCode = form.controls[CorrectionsBillToFormEnum.zipCode];
    const zipCodeComplementation = form.controls[CorrectionsBillToFormEnum.zipCodeComplementation];
    const country = form.controls[CorrectionsBillToFormEnum.country];
    const chrgsResp = form.controls[CorrectionsBillToFormEnum.chrgsResp];
    const deleteBillTo = form.controls[CorrectionsBillToFormEnum.deleteBillTo];

    return {
      customerNumber,
      madCode,
      nameOne,
      nameTwo,
      address,
      city,
      state,
      zipCode,
      zipCodeComplementation,
      country,
      chrgsResp,
      deleteBillTo
    };
  };
}

import { FormGroup } from "@angular/forms";
import { CorrectionsFormEnum } from "@shared/enums/corrections/corrections-form/corrections-form.enum";
import { CorrectionsShipperFormEnum } from "@shared/enums/corrections/corrections-form/shipper/corrections-shipper-form.enum";
import { CorrectionsShipperFormModel } from "@shared/models/corrections/corrections-form/shipper/corrections-shipper.model";

export abstract class CorrectionsShipperGetForm {
  static getForm = (form: FormGroup): FormGroup => {
    return form.controls[CorrectionsFormEnum.shipper] as FormGroup;
  }

  static getFormValues = (form: FormGroup): CorrectionsShipperFormModel => {
    const customerNumber = form.controls[CorrectionsShipperFormEnum.customerNumber];
    const madCode = form.controls[CorrectionsShipperFormEnum.madCode];
    const nameOne = form.controls[CorrectionsShipperFormEnum.nameOne];
    const nameTwo = form.controls[CorrectionsShipperFormEnum.nameTwo];
    const address = form.controls[CorrectionsShipperFormEnum.address];
    const city = form.controls[CorrectionsShipperFormEnum.city];
    const state = form.controls[CorrectionsShipperFormEnum.state];
    const zipCode = form.controls[CorrectionsShipperFormEnum.zipCode];
    const zipCodeComplementation = form.controls[CorrectionsShipperFormEnum.zipCodeComplementation];
    const country = form.controls[CorrectionsShipperFormEnum.country];
    const creditStatus = form.controls[CorrectionsShipperFormEnum.creditStatus];

    return {
      customerNumber,
      madCode,
      nameOne,
      nameTwo,
      address,
      city,
      state,
      zipCode,
      zipCodeComplementation,
      country,
      creditStatus
    };
  };
}

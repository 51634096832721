import { Pipe, PipeTransform } from '@angular/core';
import { DateFormat, DateTimezone } from '@shared/enums/common-data/date-format.enum';
import { DateUtils } from '@shared/utils/date/date-utils.service';

@Pipe({
  name: 'dateTimeXpo',
})
export class DateTimeXpoPipe implements PipeTransform {
  transform(dateTime: number, timezone = DateTimezone.local): string {
    return dateTime ? DateUtils.getDateFromTimeStamp(dateTime, DateFormat.timestamp, timezone) : '';
  }
}

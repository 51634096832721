import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CorrectionsBillToGetForm } from '@corrections/corrections-form/shared/form/billto/corrections-form-billto-get-form-groups.utils';
import { ErrorModel } from '@shared/models/shared/error.model';

@Injectable()
export class BillToValidatorsService {

  constructor() { }

  requiredFields(form: FormGroup): ErrorModel[] {
    const billToForm = CorrectionsBillToGetForm.getForm(form);
    const billToControls = CorrectionsBillToGetForm.getFormValues(billToForm);

    // NOTE: The mandatory fields only applies when there is a mad code
    if (!billToControls.madCode.value) return [];

    const mandatoryEmptyFields = [];
    if(!billToControls.nameOne.value) {
      mandatoryEmptyFields.push('name');
    }

    if(!billToControls.address.value) {
      mandatoryEmptyFields.push('address');
    }

    if(!billToControls.country.value) {
      mandatoryEmptyFields.push('country');
    }

    if(!billToControls.city.value) {
      mandatoryEmptyFields.push('city');
    }

    if(!billToControls.state.value) {
      mandatoryEmptyFields.push('state');
    }

    if(!billToControls.zipCode.value) {
      mandatoryEmptyFields.push('zipCode');
    }

    if (mandatoryEmptyFields.length === 0) return [];

    const errorMessage = mandatoryEmptyFields.length === 1 ? `BillTo required field` : `BillTo required fields`;
    const errors: ErrorModel[] = [
      {
        message: `${errorMessage}: ${mandatoryEmptyFields.join(', ')}`
      }
    ];
    return errors;
  }
}

import { Injectable } from '@angular/core';
import { SnackBarModel } from '@shared/models/shared/snack-bar.model';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';

export const snackBarConfig = {
  duration: {
    short: 2000,
    mid: 8000,
    long: 15000,
    infinity: 0,
  },
};

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(public snackBar: XpoSnackBar) { }

  open(props: SnackBarModel): void {
    const { message, linkAction, duration, status } = props;
    this.snackBar.open({
      message,
      linkAction,
      status: status,
      matConfig: {
        duration: duration || snackBarConfig.duration.mid,
      },
    });
  }

  /**
   * @deprecated This method will be removed in future version, it is not recomended to use it, instead use open method.
   */
  openErrorSnackBar(errorSnackBar: { message: string, duration?: number, avoidToDisplay?: boolean}): void {
    const { avoidToDisplay, message, duration } = errorSnackBar;
    if (avoidToDisplay) return;

    this.snackBar.open({
      message,
      status: 'error',
      matConfig: {
        duration: duration || snackBarConfig.duration.mid,
      },
    });
  }
}

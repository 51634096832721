import { Injectable } from '@angular/core';
import { CorrectionsFormFooterTooltipEnum } from '@shared/enums/corrections/corrections-form/corrections-form-footer.enum';
import { CorrectionsMiscellaneousFormTextEnum } from '@shared/enums/corrections/corrections-form/miscellaneous/corrections-miscellaneous-form.enum';
import { TooltipConfigModel } from '@shared/models/corrections/corrections-form/corrections-form.model';
import { UserRolesService } from '../user-roles.service';

@Injectable()
export class RequestFormRolesService {

  constructor(
    private userRolesService: UserRolesService
  ) { }

  addAutoRateValidation(): boolean {
    return this.userRolesService.checkIfAnyRoleMatch(
      [
        this.userRolesService.ramRolesKeyEnum.auditorRamRole,
        this.userRolesService.ramRolesKeyEnum.inspectorRamRole,
        this.userRolesService.ramRolesKeyEnum.managerRamRole,
        this.userRolesService.ramRolesKeyEnum.supervisorRamRole,
        this.userRolesService.ramRolesKeyEnum.swoAuditorRamRole,
        this.userRolesService.ramRolesKeyEnum.vicePresidentRamRole,
      ],
      this.userRolesService.getLoggedUserRolesValue()
    );
  }

  sendManuallyRequestInd(): boolean {
    return this.hasOnlyRequesterRole();
  }

  disableAccessorialRateField(): boolean {
    return !this.userRolesService.checkIfAnyRoleMatch(
      [
        this.userRolesService.ramRolesKeyEnum.auditorRamRole,
        this.userRolesService.ramRolesKeyEnum.inspectorRamRole,
        this.userRolesService.ramRolesKeyEnum.managerRamRole,
        this.userRolesService.ramRolesKeyEnum.supervisorRamRole,
        this.userRolesService.ramRolesKeyEnum.swoAuditorRamRole,
        this.userRolesService.ramRolesKeyEnum.vicePresidentRamRole,
      ],
      this.userRolesService.getLoggedUserRolesValue()
    );
  }

  disableAmcAmountTextField(): boolean {
    return !this.userRolesService.checkIfAnyRoleMatch(
      [
        this.userRolesService.ramRolesKeyEnum.auditorRamRole,
        this.userRolesService.ramRolesKeyEnum.inspectorRamRole,
        this.userRolesService.ramRolesKeyEnum.managerRamRole,
        this.userRolesService.ramRolesKeyEnum.supervisorRamRole,
        this.userRolesService.ramRolesKeyEnum.swoAuditorRamRole,
        this.userRolesService.ramRolesKeyEnum.vicePresidentRamRole,
      ],
      this.userRolesService.getLoggedUserRolesValue()
    );
  }

  disableCommodityAmountField(): boolean {
    return !this.userRolesService.checkIfAnyRoleMatch(
      [
        this.userRolesService.ramRolesKeyEnum.auditorRamRole,
        this.userRolesService.ramRolesKeyEnum.inspectorRamRole,
        this.userRolesService.ramRolesKeyEnum.managerRamRole,
        this.userRolesService.ramRolesKeyEnum.supervisorRamRole,
        this.userRolesService.ramRolesKeyEnum.swoAuditorRamRole,
        this.userRolesService.ramRolesKeyEnum.vicePresidentRamRole,
      ],
      this.userRolesService.getLoggedUserRolesValue()
    );
  }

  disableCommodityRateField(): boolean {
    return !this.userRolesService.checkIfAnyRoleMatch(
      [
        this.userRolesService.ramRolesKeyEnum.auditorRamRole,
        this.userRolesService.ramRolesKeyEnum.inspectorRamRole,
        this.userRolesService.ramRolesKeyEnum.managerRamRole,
        this.userRolesService.ramRolesKeyEnum.supervisorRamRole,
        this.userRolesService.ramRolesKeyEnum.swoAuditorRamRole,
        this.userRolesService.ramRolesKeyEnum.vicePresidentRamRole,
      ],
      this.userRolesService.getLoggedUserRolesValue()
    );
  }

  disableSendMeCopyEmailButon(): boolean {
    return this.hasOnlyRequesterRole();
  }

  displayAsDeficitWeightSection(): boolean {
    return this.userRolesService.checkIfAnyRoleMatch(
      [
        this.userRolesService.ramRolesKeyEnum.auditorRamRole,
        this.userRolesService.ramRolesKeyEnum.inspectorRamRole,
        this.userRolesService.ramRolesKeyEnum.managerRamRole,
        this.userRolesService.ramRolesKeyEnum.supervisorRamRole,
        this.userRolesService.ramRolesKeyEnum.swoAuditorRamRole,
        this.userRolesService.ramRolesKeyEnum.vicePresidentRamRole,
      ],
      this.userRolesService.getLoggedUserRolesValue()
    );
  }

  displayAuditorRemarks(): boolean {
    return this.userRolesService.checkIfAnyRoleMatch(
      [
        this.userRolesService.ramRolesKeyEnum.auditorRamRole,
        this.userRolesService.ramRolesKeyEnum.inspectorRamRole,
        this.userRolesService.ramRolesKeyEnum.managerRamRole,
        this.userRolesService.ramRolesKeyEnum.supervisorRamRole,
        this.userRolesService.ramRolesKeyEnum.swoAuditorRamRole,
        this.userRolesService.ramRolesKeyEnum.vicePresidentRamRole,
      ],
      this.userRolesService.getLoggedUserRolesValue()
    );
  }

  displayLockProMessage(): boolean {
    return this.userRolesService.checkIfAnyRoleMatch(
      [
        this.userRolesService.ramRolesKeyEnum.auditorRamRole,
        this.userRolesService.ramRolesKeyEnum.inspectorRamRole,
        this.userRolesService.ramRolesKeyEnum.managerRamRole,
        this.userRolesService.ramRolesKeyEnum.supervisorRamRole,
        this.userRolesService.ramRolesKeyEnum.swoAuditorRamRole,
        this.userRolesService.ramRolesKeyEnum.vicePresidentRamRole,
      ],
      this.userRolesService.getLoggedUserRolesValue()
    );
  }

  displayAutoAndManualRateBtn(): boolean {
    return this.userRolesService.checkIfAnyRoleMatch(
      [
        this.userRolesService.ramRolesKeyEnum.auditorRamRole,
        this.userRolesService.ramRolesKeyEnum.inspectorRamRole,
        this.userRolesService.ramRolesKeyEnum.managerRamRole,
        this.userRolesService.ramRolesKeyEnum.supervisorRamRole,
        this.userRolesService.ramRolesKeyEnum.swoAuditorRamRole,
        this.userRolesService.ramRolesKeyEnum.vicePresidentRamRole,
      ],
      this.userRolesService.getLoggedUserRolesValue()
    );
  }

  displayGuaranteedInfoIcon(): boolean {
    return this.userRolesService.checkIfAnyRoleMatch(
      [
        this.userRolesService.ramRolesKeyEnum.auditorRamRole,
        this.userRolesService.ramRolesKeyEnum.inspectorRamRole,
        this.userRolesService.ramRolesKeyEnum.managerRamRole,
        this.userRolesService.ramRolesKeyEnum.supervisorRamRole,
        this.userRolesService.ramRolesKeyEnum.swoAuditorRamRole,
        this.userRolesService.ramRolesKeyEnum.vicePresidentRamRole,
      ],
      this.userRolesService.getLoggedUserRolesValue()
    );
  }

  displayPreviewManualRateButton(): boolean {
    return this.userRolesService.checkIfAnyRoleMatch(
      [
        this.userRolesService.ramRolesKeyEnum.auditorRamRole,
        this.userRolesService.ramRolesKeyEnum.inspectorRamRole,
        this.userRolesService.ramRolesKeyEnum.managerRamRole,
        this.userRolesService.ramRolesKeyEnum.supervisorRamRole,
        this.userRolesService.ramRolesKeyEnum.swoAuditorRamRole,
        this.userRolesService.ramRolesKeyEnum.vicePresidentRamRole,
      ],
      this.userRolesService.getLoggedUserRolesValue()
    );
  }

  displayTariffRatesButton(): boolean {
    return this.userRolesService.checkIfAnyRoleMatch(
      [
        this.userRolesService.ramRolesKeyEnum.auditorRamRole,
        this.userRolesService.ramRolesKeyEnum.inspectorRamRole,
        this.userRolesService.ramRolesKeyEnum.managerRamRole,
        this.userRolesService.ramRolesKeyEnum.supervisorRamRole,
        this.userRolesService.ramRolesKeyEnum.swoAuditorRamRole,
        this.userRolesService.ramRolesKeyEnum.vicePresidentRamRole,
      ],
      this.userRolesService.getLoggedUserRolesValue()
    );
  }

  displayRecalcButton(): boolean {
    return this.userRolesService.checkIfAnyRoleMatch(
      [
        this.userRolesService.ramRolesKeyEnum.auditorRamRole,
        this.userRolesService.ramRolesKeyEnum.inspectorRamRole,
        this.userRolesService.ramRolesKeyEnum.managerRamRole,
        this.userRolesService.ramRolesKeyEnum.supervisorRamRole,
        this.userRolesService.ramRolesKeyEnum.swoAuditorRamRole,
        this.userRolesService.ramRolesKeyEnum.vicePresidentRamRole,
      ],
      this.userRolesService.getLoggedUserRolesValue()
    );
  }

  displayUserEmail(): boolean {
    return this.hasOnlyRequesterRole();
  }

  displayRevenueDifference(): boolean {
    return this.userRolesService.checkIfAnyRoleMatch(
      [
        this.userRolesService.ramRolesKeyEnum.auditorRamRole,
        this.userRolesService.ramRolesKeyEnum.inspectorRamRole,
        this.userRolesService.ramRolesKeyEnum.managerRamRole,
        this.userRolesService.ramRolesKeyEnum.supervisorRamRole,
        this.userRolesService.ramRolesKeyEnum.swoAuditorRamRole,
        this.userRolesService.ramRolesKeyEnum.vicePresidentRamRole,
      ],
      this.userRolesService.getLoggedUserRolesValue()
    );
  }

  getAutoRateTooltipMessage(): TooltipConfigModel {
    const anyRoleMatch = this.userRolesService.checkIfAnyRoleMatch(
      [
        this.userRolesService.ramRolesKeyEnum.auditorRamRole,
        this.userRolesService.ramRolesKeyEnum.inspectorRamRole,
        this.userRolesService.ramRolesKeyEnum.managerRamRole,
        this.userRolesService.ramRolesKeyEnum.supervisorRamRole,
        this.userRolesService.ramRolesKeyEnum.swoAuditorRamRole,
        this.userRolesService.ramRolesKeyEnum.vicePresidentRamRole,
      ],
      this.userRolesService.getLoggedUserRolesValue()
    );

   return {
      title: anyRoleMatch ? CorrectionsFormFooterTooltipEnum.previewAutoRateAuditorTitle : CorrectionsFormFooterTooltipEnum.previewAutoRateTitle,
      message: anyRoleMatch ? CorrectionsFormFooterTooltipEnum.previewAutoRateAuditorMessage : CorrectionsFormFooterTooltipEnum.previewAutoRateMessage
    };
  }

  getMiscellaneousTooltipMessage(): string {
    const anyRoleMatch = this.userRolesService.checkIfAnyRoleMatch(
      [
        this.userRolesService.ramRolesKeyEnum.auditorRamRole,
        this.userRolesService.ramRolesKeyEnum.inspectorRamRole,
        this.userRolesService.ramRolesKeyEnum.managerRamRole,
        this.userRolesService.ramRolesKeyEnum.supervisorRamRole,
        this.userRolesService.ramRolesKeyEnum.swoAuditorRamRole,
        this.userRolesService.ramRolesKeyEnum.vicePresidentRamRole,
      ],
      this.userRolesService.getLoggedUserRolesValue()
    );

    return anyRoleMatch ? CorrectionsMiscellaneousFormTextEnum.messageAuditor : CorrectionsMiscellaneousFormTextEnum.message;
  }

  sendMeCopyDefaultEmail(): boolean {
    return this.hasOnlyRequesterRole();
  }

  private hasOnlyRequesterRole(): boolean {
    const isRequester = this.userRolesService.checkIfAnyRoleMatch([this.userRolesService.ramRolesKeyEnum.requester], this.userRolesService.getLoggedUserRolesValue());
    if (!isRequester) return false;

    const hasAnyOfHigherRoles = this.userRolesService.checkIfAnyRoleMatch(
      [
        this.userRolesService.ramRolesKeyEnum.auditorRamRole,
        this.userRolesService.ramRolesKeyEnum.inspectorRamRole,
        this.userRolesService.ramRolesKeyEnum.managerRamRole,
        this.userRolesService.ramRolesKeyEnum.supervisorRamRole,
        this.userRolesService.ramRolesKeyEnum.swoAuditorRamRole,
        this.userRolesService.ramRolesKeyEnum.vicePresidentRamRole,
      ],
      this.userRolesService.getLoggedUserRolesValue()
    );
    return !hasAnyOfHigherRoles;
  }
}

import { FormGroup } from "@angular/forms";
import { CorrectionsConsigneeFormEnum } from "@shared/enums/corrections/corrections-form/consignee/corrections-consignee-form.enum";
import { CorrectionsFormEnum } from "@shared/enums/corrections/corrections-form/corrections-form.enum";
import { CorrectionsConsigneeFormModel } from "@shared/models/corrections/corrections-form/consignee/corrections-consignee.model";

export abstract class CorrectionsConsigneeGetForm {
  static getForm = (form: FormGroup): FormGroup => {
    return form.controls[CorrectionsFormEnum.consignee] as FormGroup;
  }

  static getFormValues = (form: FormGroup): CorrectionsConsigneeFormModel => {
    const customerNumber = form.controls[CorrectionsConsigneeFormEnum.customerNumber];
    const madCode = form.controls[CorrectionsConsigneeFormEnum.madCode];
    const nameOne = form.controls[CorrectionsConsigneeFormEnum.nameOne];
    const nameTwo = form.controls[CorrectionsConsigneeFormEnum.nameTwo];
    const address = form.controls[CorrectionsConsigneeFormEnum.address];
    const city = form.controls[CorrectionsConsigneeFormEnum.city];
    const state = form.controls[CorrectionsConsigneeFormEnum.state];
    const zipCode = form.controls[CorrectionsConsigneeFormEnum.zipCode];
    const zipCodeComplementation = form.controls[CorrectionsConsigneeFormEnum.zipCodeComplementation];
    const country = form.controls[CorrectionsConsigneeFormEnum.country];
    const creditStatus = form.controls[CorrectionsConsigneeFormEnum.creditStatus];

    return {
      customerNumber,
      madCode,
      nameOne,
      nameTwo,
      address,
      city,
      state,
      zipCode,
      zipCodeComplementation,
      country,
      creditStatus
    };
  };
}

import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CorrectionsShipperGetForm } from '@corrections/corrections-form/shared/form/shipper/corrections-form-shipper-get-form-groups.utils';
import { ErrorModel } from '@shared/models/shared/error.model';

@Injectable()
export class ShipperValidatorsService {

  constructor() { }

  requiredFields(form: FormGroup): ErrorModel[] {
    const shipperForm = CorrectionsShipperGetForm.getForm(form);
    const shipperControls = CorrectionsShipperGetForm.getFormValues(shipperForm);

    // NOTE: The mandatory fields only applies when there is a mad code
    if (!shipperControls.madCode.value) return [];

    const mandatoryEmptyFields = [];
    if(!shipperControls.nameOne.value) {
      mandatoryEmptyFields.push('name');
    }

    if(!shipperControls.address.value) {
      mandatoryEmptyFields.push('address');
    }

    if(!shipperControls.country.value) {
      mandatoryEmptyFields.push('country');
    }

    if(!shipperControls.city.value) {
      mandatoryEmptyFields.push('city');
    }

    if(!shipperControls.state.value) {
      mandatoryEmptyFields.push('state');
    }

    if(!shipperControls.zipCode.value) {
      mandatoryEmptyFields.push('zipCode');
    }

    if (mandatoryEmptyFields.length === 0) return [];

    const errorMessage = mandatoryEmptyFields.length === 1 ? `Shipper required field` : `Shipper required fields`;
    const errors: ErrorModel[] = [
      {
        message: `${errorMessage}: ${mandatoryEmptyFields.join(', ')}`
      }
    ];
    return errors;
  }
}

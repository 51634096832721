export enum CorrectionsCommonSectionIdsEnum {
  accessorials = 'accessorialsSection',
  billTo = 'billToSection',
  chargeCd = 'chargeCdSection',
  commodities = 'commoditiesSection',
  consignee = 'consigneeSection',
  errors = 'errorsSection',
  reason = 'reasonSection',
  requestInfo = 'requstInfoSection',
  shiper = 'shiperSection',
}

export enum CorrectionsRateContentDetailsLabelsEnum {
  acceptManualRatedChanges = 'Accept Manual Rated Changes',
  acceptNewTariffRates = "Accept New Tariff Rates",
  advanceRevenue = 'ADVANCE REVENUE',
  amount = 'Amount',
  amountPrice = 'Amount $',
  applyManualRatedChanges = 'Apply Manual Rated Changes',
  auth = 'Auth:',
  beyondRevenue = 'BEYOND REVENUE',
  billTo = 'Bill To',
  chargeCode = 'Charge Code',
  charges = 'Charges',
  payments = 'Payments',
  balance = 'Balance Due',
  chargesPrice = 'Charges $',
  cnwyRevenue = 'CNWY Revenue',
  cnwy = 'CNWY',
  code = 'Code',
  consignee = 'Consignee',
  ctsShipper = `CTS Shipper's Number/ PO Number`,
  declineManualRatedChanges = 'Decline Manual Rated Changes',
  description = 'Description',
  dest = 'DEST',
  destination = 'Destination',
  hasBillingInstructions = 'Has Billing Instructions',
  inBond = 'IN BOND',
  lb = 'LB',
  org = 'ORG',
  origin = 'Origin',
  parentPro = 'Parent Pro',
  pcs = '# PCS',
  percentage = 'Percentage %',
  pickupDate = 'Pick-Up Date',
  pricingRulesetAfterChanges = 'Pricing Ruleset After Changes',
  pricingRulesetBeforeChanges = 'Pricing Ruleset Before Changes',
  rate = 'Rate',
  ratePrice = 'Rate $',
  rateWarning = 'The Corrections listed below could not be applied during Auto Rating. Click the “Apply Manual-Rated Changes” to view them. Changes are subject to Auditor approval',
  route = 'ROUTE (CARRIERS, PRO, DATE & NO. JCTS)',
  ruleset = '(Ruleset)',
  shipper = 'Shipper',
  totals = 'Totals',
  type = 'Type',
  unrated = 'UNRATED',
  weight = 'Weight',
  weightLbs = 'Weight (lbs)',
  poNumber = 'PO#',
  snNumber = 'SN#',
  hazmatIndicator = 'HM/'
}

export enum CorrectionsRateContentDetailsIdsEnum {
  acceptNewTariffRates = 'acceptNewTariffRates',
  applyManualRatedChanges = 'applyManualRatedChanges',
  acceptManualRatedChanges = 'acceptManualRatedChanges',
  declineManualRatedChanges = 'declineManualRatedChanges',
  pricingRulesetBeforeChanges = 'pricingRulesetBeforeChanges',
  pricingRulesetAfterChanges = 'pricingRulesetAfterChanges',
  shipperRulesetLink = 'shipperRulesetLink',
  consigneeRulesetLink = 'consigneeRulesetLink',
  billToRulesetLink = 'billToRulesetLink',
  chargeCode = 'chargeCode'
}

export enum CorrectionsMiscellaneousTypes {
  accessorial = 'Accessorial',
  commodity = 'Commodity',
  discount = 'Discount',
  asWeight = 'As Weight',
  asDeficitWeight = 'As Deficit Weight',
}

export enum CorrectionsResearchToolsTitleEnum {
  title = 'Research Tools',
}

export enum CorrectionResearchToolsLabelsEnum {
  title = 'Research Tools',
  subTitleAuditor = 'Auditor',
  subTitleRequester = 'Requester',
  buttonText = 'Research',
}

export enum CorrectionResearchToolsIdsEnum {
  button = 'researchNavBtn',
}

export enum CorrectionMainSearchLabelsEnum {
  buttonText = 'Search',
  searchPro = 'Search PRO',
}

export enum CorrectionProgramIdsEnum {
  bc07 = 'BC07',
  rt81 = 'RT81',
}
export enum CorrectionFormLockedEnum {
  lockedMessage = '{user} is already working on this correction request. All fields and buttons have been disabled. Please try again at a later time.',
}

import { createSelector } from '@ngrx/store';
import { CorrectionsReducer, CorrectionsStateModel } from '../reducer/corrections.reducer';

const selector = createSelector(CorrectionsReducer.correctionsDetailFeatureSelector, (state) => state);

const getProNbr = createSelector(selector, (state: CorrectionsStateModel) => state.proNbr);

const getBillClassCd = createSelector(selector, (state: CorrectionsStateModel) => state.correctionsDetail?.billStatus?.billClass);

const getShipmentInstId = createSelector(selector, (state: CorrectionsStateModel) => state.correctionsDetail?.shipmentInstId);

const getCurrentRequestFormLockedInd = createSelector(selector, (state: CorrectionsStateModel) => state.isCurrentRequestFormLocked);

const getRequestFormType = createSelector(selector, (state: CorrectionsStateModel) => state.requestFormType);

const getRequestFormView = createSelector(selector, (state: CorrectionsStateModel) => state.requestFormView);

const getTdcOptionRequestFormDisabled = createSelector(selector, (state: CorrectionsStateModel) => state.tdcOptionRequestFormDisabled);

const getIsUnrated = createSelector(selector, (state: CorrectionsStateModel) => state.isUnratedPro);

const getCorrectedCancelChargesInd = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.correctionsCorrectedDetail?.cancelCharges?.cancelChargesInd
);

const getCorrectionsBillCharges = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.correctionsBillCharges
);

const getCorrectionsDetail = createSelector(selector, (state: CorrectionsStateModel) => state.correctionsDetail);

const getCorrectionsCorrectedDetail = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.correctionsCorrectedDetail
);

const getCorrectionsHistoryDetail = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.correctionsHistoryDetail
);

const getListCorrectionsEditMetadata = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.correctionsEditMetadata
);

const getListCorrectionsEditReasonCategories = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.correctionsEditMetadata?.reasonCategories
);

const getListCorrectionsEditReasonDescriptions = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.correctionsEditMetadata?.reasonDescriptions
);

const getListCorrectionsAuditMetadata = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.correctionsAuditMetadata
);

const getListCorrectionsReassignMetadata = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.correctionsReassignMetadata
);

const getManualRatePreview = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.manualRatePreview
);

const getAutoRatePreview = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.autoRatePreview
);

const getLoadingGetBillCharges = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.loadingGetBillCharges
);

const getLoadingCorrectedRevenue = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.loadingCorrectedRevenue
);

const getLoadingSubmit = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.loadingSubmit
);

const getCurrentRequestForm = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.currentRequestForm
);

const getOriginalRequestForm = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.originalRequestForm
);

const getRequesterId = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.correctionsCorrectedDetail?.requestInformation?.requesterUserId
);

const updateCorrectionRequestLoading = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.updateCorrectionRequestLoading
);

const getPickupDate = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.correctionsDetail?.pickupDate
);

const getPricingAgreement = createSelector(
  selector,
  (state: CorrectionsStateModel) => state.correctionsDetail?.pricingBeforeAgreement
);

export const CorrectionsSelectors = {
  getProNbr,
  getBillClassCd,
  getIsUnrated,
  getShipmentInstId,
  getCurrentRequestFormLockedInd,
  getRequestFormType,
  getRequestFormView,
  getTdcOptionRequestFormDisabled,
  getCorrectionsBillCharges,
  getCorrectionsDetail,
  getCorrectionsCorrectedDetail,
  getPickupDate,
  getCorrectionsHistoryDetail,
  getListCorrectionsEditMetadata,
  getListCorrectionsEditReasonCategories,
  getListCorrectionsEditReasonDescriptions,
  getListCorrectionsAuditMetadata,
  getListCorrectionsReassignMetadata,
  getManualRatePreview,
  getAutoRatePreview,
  getLoadingGetBillCharges,
  getLoadingCorrectedRevenue,
  getLoadingSubmit,
  getCurrentRequestForm,
  getOriginalRequestForm,
  getRequesterId,
  updateCorrectionRequestLoading,
  getPricingAgreement,
  getCorrectedCancelChargesInd,
};

export class ObjectUtils {

  static setNullWhenIsDeleted(correctionsForm: any, originalForm: any) {
    for (const key in correctionsForm) {
      if (correctionsForm.hasOwnProperty(key) && originalForm.hasOwnProperty(key)) {
        if ((correctionsForm[key] === null || correctionsForm[key] === '') && (originalForm[key] === '' ||  originalForm[key] == null)) {
          correctionsForm[key] = originalForm[key];
        }
        else if (correctionsForm[key] === '' && (originalForm[key] !== '' &&  originalForm[key] != null)) {
          correctionsForm[key] = null;
        }
        else if (correctionsForm[key] instanceof Date) {
          continue;
        }
        else if (Array.isArray(correctionsForm[key]) && Array.isArray(originalForm[key])) {
          for (let i = 0; i < correctionsForm[key].length; i++) {
            if (typeof correctionsForm[key][i] === 'object' && typeof originalForm[key][i] === 'object') {
              this.setNullWhenIsDeleted(correctionsForm[key][i], originalForm[key][i]);
            } else if (correctionsForm[key][i] === null && (originalForm[key][i] === '' ||  originalForm[key][i] == null)) {
              correctionsForm[key][i] = originalForm[key][i];
            }
          }
        }
        else if (typeof correctionsForm[key] === 'object' && typeof originalForm[key] === 'object') {
          this.setNullWhenIsDeleted(correctionsForm[key], originalForm[key]);
        }
      }
    }
  }

  static checkIfTwoValuesHaveChanged(val1?: string | number, val2?: string | number): boolean {
    return !val1 && !val2 ? false : val1 != val2;
  }

  static checkIfEmptyObj<T>(obj: T): boolean {
    if (obj == null) return true;

    if (typeof obj === 'object' && !Array.isArray(obj)) {
      return Object.values(obj).every(
        value => value == null || (typeof value === 'string' && value.trim() === '')
      );
    }

    return false;
  }
}
